import { IndexTable, Card, useBreakpoints } from '@shopify/polaris';
import TableFilter from './table_filter';
import { useTranslation } from 'react-i18next';
import TablePagination from './table_pagination';
import { Box, Typography } from '@mui/material';
import { isEmpty } from 'src/utils/type_check';
import { useEffect, useState } from 'react';

export default function Table({ useTable, isLoading = false }) {
  const { t } = useTranslation();
  const condensed = useBreakpoints().smDown;

  const [tableData, setTableData] = useState(useTable);

  useEffect(() => {
    if (!isLoading) {
      setTableData(useTable);
    }
  }, [isLoading, useTable]);

  return (
    <Card padding={0}>
      {!isEmpty(tableData.title) ? (
        <Box padding={'12px'}>
          <Typography variant={'heading_sm'}>{tableData.title}</Typography>
        </Box>
      ) : null}
      <Box>
        <TableFilter
          useTable={tableData}
          isLoading={isLoading}
        />
      </Box>
      <div
        id={'mint_table'}
        style={{
          overflowY: tableData.tableScroll ? 'auto' : null,
          maxHeight: tableData.tableScroll ? '650px' : null,
          paddingBottom: tableData.tableScroll ? '10px' : null,
        }}
      >
        <IndexTable
          condensed={condensed}
          itemCount={tableData.data.length}
          selectedItemsCount={
            tableData.allResourcesSelected ? t('common:all') : tableData.selectedResources.length
          }
          onSelectionChange={tableData.handleSelectionChange}
          headings={tableData.headers}
          promotedBulkActions={tableData.bulkAction}
          selectable={tableData.selectable}
          emptyState={
            tableData.emptyStateMarkup === undefined
              ? null
              : tableData.emptyStateMarkup ?? tableData.emptyStateMarkup()
          }
        >
          {tableData.rowMarkup}
        </IndexTable>
      </div>
      {tableData.showPagination && (
        <TablePagination
          useTable={tableData}
          isLoading={isLoading}
        />
      )}
    </Card>
  );
}
