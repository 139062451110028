import { confirmable } from 'react-confirm';
import StackedModal from './stacked_modal';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { RadioButton } from '@shopify/polaris';
import { Stack, Typography } from '@mui/material';
import { utils, writeFile } from 'xlsx';
import { fDateTime } from 'src/utils/format_time';

function ExportDialog({
  show,
  proceed,
  singularName,
  pluralName,
  selectedData = [],
  exportData,
  params,
  data,
  excelData,
  exportSelect = true,
}) {
  const { t } = useTranslation(['excel']);
  const [option, setOption] = useState(null);
  const onSubmit = async () => {
    var res = [];
    if (option === 'all') {
      const allData = await exportData({ ...params, pageSize: 5000 });
      res = allData;
    } else if (option === 'selected') {
      res = selectedData;
    } else if (option === 'current') {
      res = data;
    }
    if (res.length > 0) {
      res = res.map((x) => excelData(x));
      const ws = utils.json_to_sheet(res);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, pluralName);
      var stamp = fDateTime(new Date());
      writeFile(wb, `${pluralName}${stamp}.xlsx`);
      proceed(true);
    }
  };
  const content = (
    <Stack>
      <Typography
        variant={'body_md'}
        gutterBottom
      >
        {t('common:export')}
      </Typography>
      <RadioButton
        label={t('excel:current')}
        checked={option === 'current'}
        onChange={() => setOption('current')}
        disabled={!data.length}
      />
      <RadioButton
        label={`${t('common:all')} ${pluralName}`}
        checked={option === 'all'}
        onChange={() => setOption('all')}
        disabled={!data.length}
      />
      {exportSelect && (
        <RadioButton
          label={`${t('common:selected')}: ${selectedData.length} ${selectedData.length === 1 ? singularName : pluralName}`}
          checked={option === 'selected'}
          onChange={() => setOption('selected')}
          disabled={!selectedData.length}
        />
      )}
    </Stack>
  );

  return (
    <StackedModal
      isOpen={show}
      title={`${t('common:export')} ${pluralName}`}
      content={content}
      primaryAction={{
        content: t('common:export'),
        onAction: onSubmit,
        disabled: option === null,
      }}
      secondaryAction={{
        content: t('common:cancel'),
        onAction: () => {
          proceed(false);
        },
      }}
      onClose={() => {
        proceed(false);
      }}
    />
  );
}

export default confirmable(ExportDialog);
