import React, { useState, useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack, Typography, InputLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { locationFormSchema } from 'src/utils/schema';
import CommonSelect from 'src/components/hook_form/common_select';
import FormProvider from 'src/components/hook_form/form_provider';
import RHFTextField from 'src/components/hook_form/rhf_text_field';
import {
  Box,
  Card,
  Text,
  InlineGrid,
  InlineStack,
  Button,
  BlockStack,
  Divider,
  Banner,
} from '@shopify/polaris';
import { createLocation, editLocation, deleteLocation, useGetLocation } from 'src/api/location_api';
import { deleteCourierFromLocation } from 'src/api/mapping_api';
import { COUNTY_CODE_SHORT } from 'src/api/common_code/country_code';
import { DIMENSION_UNIT } from 'src/api/common_code/dimension_unit';
import { WEIGHT_UNIT } from 'src/api/common_code/weight_unit';
import CarrierInfo from 'src/components/cards/carrier_info';
import Loading from 'src/components/loading';
import { chooseLan } from 'src/utils/language_code';
import { requiredField } from 'src/utils/format_data';
import { isEmpty } from 'src/utils/type_check';
import { toast } from 'react-toastify';
import { deactivateConfirm } from 'src/components/dialog/confirmation';
import { getDirtyFields } from 'src/utils/format_data';
import { LOCATION_TYPES } from 'src/api/common_code/location_type';
import { PlusIcon, CaretUpIcon } from '@shopify/polaris-icons';
import { ENDPOINTS } from 'src/api/endpoints';
import { mutate } from 'swr';

const LocationForm = ({ onSuccess, locationId, onConnect }) => {
  const { t } = useTranslation(['settings']);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [deleted, setDeleted] = useState(false);

  const {
    searchLocation: locationData,
    searchCouriers: couriers,
    searchLoading: loading,
  } = useGetLocation(locationId);

  const initMore = (data) => {
    if (data) {
      if (
        data.state ||
        data.city ||
        data.postalCode ||
        data.managetTelNumber ||
        data.address1Local ||
        data.address2Local
      )
        return true;
    }
    return false;
  };

  const [more, setMore] = useState(locationData ? initMore(locationData) : false);

  const defaultValues = useMemo(
    () => ({
      locationNameLocal: locationData?.locationNameLocal || '',
      locationNameEnglish: locationData?.locationNameEnglish || '',
      countryCode: locationData?.countryCode || '',
      locationType: locationData?.locationType || '',
      locationCode: locationData?.locationCode || '',
      postalCode: locationData?.postalCode || '',
      state: locationData?.state || '',
      city: locationData?.city || '',
      address1Local: locationData?.address1Local || '',
      address2Local: locationData?.address2Local || '',
      managerTelNumber: locationData?.managerTelNumber || '',
      weightUnit: locationData?.weightUnit || '',
      dimensionUnit: locationData?.dimensionUnit || '',
      isActive: locationData?.isActive ?? true,
    }),
    [locationData],
  );

  useEffect(() => {
    setDeleted(locationData && locationData.isActive === false);
  }, [locationData]);

  const methods = useForm({
    resolver: yupResolver(locationFormSchema(t)),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = methods;

  var isEdit = !isEmpty(locationData?.locationId);

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const onConnectSuccess = () => {
    setSuccessMsg(t('settings:locations.courier_connected'));
    var URL = ENDPOINTS.location.get(locationId);
    mutate(URL);
  };

  const onSubmit = handleSubmit(async (data) => {
    data = getDirtyFields(dirtyFields, data);
    if (deleted) {
      data.isActive = true;
    }
    setErrorMsg('');
    setSuccessMsg('');
    const apiFunction = isEdit
      ? editLocation(data, locationData?.locationId)
      : createLocation(data);
    try {
      var res = await apiFunction;
      if (res.isSuccess) {
        if (isEdit) {
          setSuccessMsg(t('settings:locations.form.update_success'));
          if (deleted) {
            onSuccess();
          }
        } else {
          onSuccess();
        }
      } else {
        console.log('ERROR in handleSubmit', res);
        setErrorMsg(res.errorMessages[0] || '');
      }
    } catch (error) {
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const handleDelete = async () => {
    try {
      const confirmation = await deactivateConfirm();
      if (confirmation) {
        var res = await deleteLocation(locationData?.locationId);
        if (res.isSuccess) {
          const name = chooseLan(
            locationData?.locationNameLocal,
            locationData?.locationNameEnglish,
          );
          toast.success(name + '\n' + t('settings:locations.location_was_deleted'));
          onSuccess();
        } else {
          console.log(res);
          setErrorMsg(
            t('settings:locations.location_was_not_deleted') + '\n' + res.errorMessages[0] || '',
          );
        }
      }
    } catch (error) {
      setErrorMsg(
        'settings:locations.location_was_not_deleted',
        +typeof error === 'string' ? error : error.message,
      );
    }
  };

  const disconnect = async (locationId, courierId) => {
    try {
      const response = await deleteCourierFromLocation(locationId, courierId);
      if (response.isSuccess) {
        setSuccessMsg(t('settings:locations.courier_disconnected'));
        var URL = ENDPOINTS.location.get(locationId);
        mutate(URL);
      } else {
        setErrorMsg(response.errorMessages[0]);
      }
    } catch (error) {
      console.log(error);
      setErrorMsg(error.message);
    }
  };

  const renderCouriers = () => {
    if (loading) {
      return <Loading />;
    }
    if (couriers.length === 0) {
      return (
        <Box paddingBlockStart={200}>
          <Text variant="bodySm">{t('settings:locations.form.no_carriers')}</Text>
        </Box>
      );
    }
    return (
      <Box>
        <InlineStack wrap={true}>
          {couriers.map((c) => (
            <Box
              key={c.courierId}
              width="50%"
              paddingBlockEnd={200}
              paddingInlineEnd={200}
            >
              {c && (
                <Card>
                  <CarrierInfo
                    key={c.courierId}
                    name={chooseLan(c.courierNameLocal, c.courierNameEnglish)}
                    avatar={c.representativeImageUrl || ''}
                    description={c.courierCode}
                    buttonText={c.isMapping ? t('common:disconnect') : t('common:connect')}
                    onButtonClick={() => {
                      c.isMapping
                        ? disconnect(locationData?.locationId, c.courierId)
                        : onConnect(locationData?.locationId, c.courierId, onConnectSuccess);
                    }}
                  />
                </Card>
              )}
            </Box>
          ))}
        </InlineStack>
      </Box>
    );
  };

  const renderAdditionalInfo = (
    <Box padding={400}>
      <Stack spacing={1}>
        <InlineGrid
          columns={4}
          gap="200"
        >
          <RHFTextField
            name="state"
            displayName={t('common:field.province')}
            disabled={deleted}
          />
          <RHFTextField
            name="city"
            displayName={t('common:field.city')}
            disabled={deleted}
          />
          <RHFTextField
            name="postalCode"
            displayName={t('common:field.postal_code')}
            disabled={deleted}
          />
          <RHFTextField
            name="managerTelNumber"
            displayName={t('common:field.phone')}
            disabled={deleted}
          />
        </InlineGrid>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <RHFTextField
            name="address1Local"
            displayName={t('common:field.address1')}
            disabled={deleted}
          />
          <Stack>
            <InputLabel>
              <Typography variant="body_md">{t('common:field.address2')}</Typography>
            </InputLabel>
            <InlineStack
              wrap={false}
              blockAlign="center"
              gap="200"
            >
              <RHFTextField
                name="address2Local"
                disabled={deleted}
              />
              <div
                style={{
                  marginTop: 'var(--p-space-100)',
                }}
              >
                <Button
                  icon={CaretUpIcon}
                  onClick={() => setMore(!more)}
                  size="large"
                />
              </div>
            </InlineStack>
          </Stack>
        </InlineGrid>
      </Stack>
    </Box>
  );
  const renderForm = (
    <Stack spacing={1.5}>
      {!!errorMsg && (
        <Banner
          tone="critical"
          onDismiss={() => setErrorMsg('')}
        >
          {errorMsg}
        </Banner>
      )}
      {!!successMsg && (
        <Banner
          tone="success"
          onDismiss={() => setSuccessMsg('')}
        >
          {successMsg}
        </Banner>
      )}
      <Card padding={0}>
        <Box padding={400}>
          <Box paddingBlockEnd={200}>
            <Typography variant="body_md_semibold">{t('common:field.section_name')}</Typography>
          </Box>
          <Stack spacing={1}>
            <InlineGrid
              columns={3}
              gap="200"
            >
              <CommonSelect
                data={COUNTY_CODE_SHORT}
                name="countryCode"
                displayName={requiredField(t('common:field.country'))}
                disabled={deleted}
              />
              <RHFTextField
                name="locationNameLocal"
                displayName={requiredField(t('common:field.nameLocal'))}
                disabled={deleted}
              />
              <RHFTextField
                name="locationNameEnglish"
                displayName={t('common:field.nameEnglish')}
                disabled={deleted}
              />
            </InlineGrid>
            <InlineGrid
              columns={2}
              gap="200"
            >
              <CommonSelect
                data={LOCATION_TYPES}
                name="locationType"
                displayName={requiredField(t('common:field.location_type'))}
                disabled={deleted}
              />
              <RHFTextField
                name="locationCode"
                displayName={t('common:field.code')}
                disabled={true}
              />
            </InlineGrid>
          </Stack>
        </Box>
        <Divider />
        {more ? (
          renderAdditionalInfo
        ) : (
          <Box padding={400}>
            <Button
              variant="plain"
              icon={PlusIcon}
              onClick={() => setMore(!more)}
            >
              {isEdit ? t('common:field.more_information') : t('common:field.add_information')}
            </Button>
          </Box>
        )}
      </Card>
      <Card>
        <Box paddingBlockEnd={200}>
          <Typography variant="body_md_semibold">{t('common:field.section_dimension')}</Typography>
        </Box>
        <InlineGrid
          columns={2}
          gap="200"
        >
          <CommonSelect
            data={DIMENSION_UNIT}
            name="dimensionUnit"
            displayName={requiredField(t('common:dimensions.dimension_unit'))}
            disabled={deleted}
            labelAsValue={true}
          />

          <CommonSelect
            data={WEIGHT_UNIT}
            name="weightUnit"
            displayName={requiredField(t('common:dimensions.weight_unit'))}
            disabled={deleted}
            labelAsValue={true}
          />
        </InlineGrid>
      </Card>
      {locationData && Object.keys(locationData).length > 0 && (
        <Card>
          <Box paddingBlockEnd={200}>
            <Typography variant="body_md_semibold">{t('common:field.section_carrier')}</Typography>
          </Box>
          {renderCouriers()}
        </Card>
      )}
    </Stack>
  );

  return (
    <FormProvider
      methods={methods}
      onSubmit={onSubmit}
    >
      {renderForm}
      <BlockStack inlineAlign="end">
        <Box paddingBlockStart={300}>
          <InlineStack gap={200}>
            {isEdit && !deleted && (
              <Button
                size={'large'}
                variant="tertiary"
                tone="critical"
                onClick={handleDelete}
              >
                {t('common:deactivate')}
              </Button>
            )}

            <Button
              submit={true}
              loading={isSubmitting}
              size={'large'}
              variant="primary"
              disabled={deleted ? !deleted : !isDirty}
            >
              {deleted
                ? t('common:enable')
                : locationData?.locationId
                  ? t('common:save')
                  : t('common:submit')}
            </Button>
          </InlineStack>
        </Box>
      </BlockStack>
    </FormProvider>
  );
};

export default LocationForm;
