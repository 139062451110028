import useTable from 'src/hooks/use_table';
import Table from 'src/components/table/table';
import { print } from 'src/utils/log';
import i18n from 'src/locales/i18n';
import { Badge, Icon, IndexTable, OptionList } from '@shopify/polaris';
import { useGetActiveUser, useGetMe } from 'src/api/user_api';
import { ROLES } from 'src/layouts/guard/role_guard';
import { useState } from 'react';
import { chooseLan } from 'src/utils/language_code';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { EditIcon } from '@shopify/polaris-icons';
import { getUserName } from 'src/utils/format_data';
import { isEmpty } from 'src/utils/type_check';
import EmptyStateCard from 'src/components/cards/empty_state_card';

const TYPE = {
  admin: 'admin',
  location: 'location',
  customer: 'customer',
};

const headers = (type) => {
  var name = { title: i18n.t('common:field.name') };
  var email = { title: i18n.t('common:field.email') };
  var country = { title: i18n.t('common:field.country') };
  var locationManager = { title: i18n.t('common:roles.location_manager') };
  var worker = { title: i18n.t('common:roles.worker') };
  if (type === TYPE.admin) return [name, country, email, { title: '' }];
  if (type === TYPE.location) return [name, country, locationManager, worker];
  if (type === TYPE.customer) return [name, country, email, { title: '' }];
};

const rowMarkup = ({ data }, type, handleClick, handleCustomerDetail, handleLocationDetail) => {
  return data.map((user, index) => {
    var {
      userId,
      locationId,
      countryCode,
      loginId,
      locationManagerCount,
      workerCount,
      locationNameLocal,
      locationNameEnglish,
    } = user;
    var id = type === TYPE.location ? locationId : userId;
    var name =
      type === TYPE.location
        ? chooseLan(locationNameLocal, locationNameEnglish)
        : getUserName(user);
    var width = type === TYPE.location ? '25%' : '33%';
    var onClick = () =>
      type === TYPE.location
        ? handleLocationDetail({ name: name, locationId: locationId }, type)
        : type === TYPE.customer
          ? handleCustomerDetail({ name: name, customerId: userId }, type)
          : null;
    return (
      <IndexTable.Row
        id={id}
        key={id}
        position={index}
        onClick={onClick}
      >
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {type !== TYPE.admin ? (
            <Link underline="hover">
              <Typography variant="body_md_medium">{name}</Typography>{' '}
            </Link>
          ) : (
            <Typography variant="body_md_medium">{name}</Typography>
          )}
        </Box>
        <Box
          component={IndexTable.Cell}
          sx={{ width: width }}
        >
          {countryCode}
        </Box>
        {type !== TYPE.location && (
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {loginId}
          </Box>
        )}
        {type === TYPE.location && (
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {locationManagerCount}
          </Box>
        )}
        {type === TYPE.location && (
          <Box
            component={IndexTable.Cell}
            sx={{ width: width }}
          >
            {workerCount}
          </Box>
        )}
        {type !== TYPE.location && (
          <Box
            component={IndexTable.Cell}
            sx={{ width: '20px' }}
          >
            <IconButton
              sx={{ padding: 0.1 }}
              onClick={(e) => {
                e.stopPropagation();
                handleClick(user, type);
              }}
            >
              <Icon source={EditIcon} />
            </IconButton>
          </Box>
        )}
      </IndexTable.Row>
    );
  });
};

export const tabs = (count, role) => {
  var admin = {
    label: i18n.t('common:roles.admin'),
    value: TYPE.admin,
    onClick: () => {},
    badge: <Badge>{count.admin}</Badge>,
  };
  var location = {
    label: i18n.t('common:field.location'),
    value: TYPE.location,
    onClick: () => {},
    badge: <Badge>{count.location}</Badge>,
  };
  var customer = {
    label: i18n.t('common:field.customer'),
    value: TYPE.customer,
    onClick: () => {},
    badge: <Badge>{count.customer}</Badge>,
  };
  if (role === ROLES.ADMIN || role === ROLES.MANAGER) return [admin, location, customer];
  if (role === ROLES.LOCATION_MANAGER) return [location];
};

export const initialFilters = {
  isActive: {
    label: i18n.t('common:active'),
    value: true,
  },
};

const options = [
  {
    label: i18n.t('common:inactive'),
    value: false,
  },
];

export const filters = (state, setState) => {
  return [
    {
      key: 'isActive',
      label: i18n.t('common:active'),
      filter: (
        <OptionList
          onChange={(selected) => setState('isActive', selected[0])}
          options={options}
          selected={[state.isActive.value]}
        />
      ),
      pinned: true,
      shortcut: true,
    },
  ];
};

export function label(key, value) {
  switch (key) {
    case 'isActive':
      return value ? i18n.t('common:active') : i18n.t('common:inactive');
    default:
      return value;
  }
}

export default function UsersTable({
  handleClick,
  handleCustomerDetail,
  handleLocationDetail,
  tabType,
}) {
  const { role } = useGetMe();
  const [getActive, setGetActive] = useState(true);
  const [q, setQ] = useState('');
  const { searchCount, customersList, adminsList, locationsList, searchLoading } = useGetActiveUser(
    { q, isActive: getActive },
  );
  const [type, setType] = useState(
    !isEmpty(tabType)
      ? tabType
      : role === ROLES.ADMIN || role === ROLES.MANAGER
        ? TYPE.admin
        : role === ROLES.LOCATION_MANAGER
          ? TYPE.location
          : '',
  );

  const getData = () => {
    if (type === TYPE.admin) return adminsList;
    if (type === TYPE.location) return locationsList;
    if (type === TYPE.customer) return customersList;
    return [];
  };

  const table = useTable({
    data: getData() ?? [],
    headers: headers(type),
    tabItems: tabs(searchCount ?? {}, role),
    defaultTab: type,
    rowMarkup: (props) =>
      rowMarkup(props, type, handleClick, handleCustomerDetail, handleLocationDetail),
    showRowsPerPage: false,
    showPagination: false,
    selectable: false,
    emptyStateMarkup: (
      <EmptyStateCard
        heading={i18n.t('settings:users.no_user_data')}
        body={i18n.t('settings:users.no_user_data_body')}
        image="/images/user_empty_icon.png"
        card={false}
        width={60}
      />
    ),
    initialFilters: initialFilters,
    filters: (state, setState) => filters(state, setState),
    disambiguateLabel: label,
  });

  if (table.isRefetch.value) {
    setType(table.tabItems[table.tabIndex].value);
    setGetActive(table.filterValues.isActive);
    setQ(table.debounceSearchQuery);
    table.onRefetched();
  }

  print('users table load');

  return (
    <Table
      useTable={table}
      isLoading={searchLoading}
    />
  );
}
