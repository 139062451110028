import * as Yup from 'yup';

export const loginSchema = (t) =>
  Yup.object().shape({
    email: emailSchema(t),
    password: passwordSchema(t),
  });

export const emailSchema = (t) =>
  Yup.string().required(t('validation:email_required')).email(t('validation:email'));

export const emailSchemaNotRequired = (t) => Yup.string().email(t('validation:email'));

export const passwordSchema = (t) =>
  Yup.string().required(t('validation:password_required')).min(4, t('validation:password_length'));

export const confirmPasswordSchema = (ref, t) =>
  Yup.string()
    .required(t('validation:confirm_password_required'))
    .oneOf([Yup.ref(ref)], t('validation:password_match'));

export const forgotPasswordSchema = (t) =>
  Yup.object().shape({
    email: emailSchema(t),
  });

export const userFormSchema = (t, isEditing) =>
  Yup.object().shape({
    userFirstNameLocal: Yup.string().required(t('validation:first_name_required')),
    userLastNameLocal: Yup.string(),
    userFirstNameEnglish: Yup.string(),
    userLastNameEnglish: Yup.string(),
    loginId: emailSchema(t),
    loginPassword: isEditing ? Yup.string() : passwordSchema(t),
    roleId: roleSchema(t),
    upperUserId: Yup.string(),
    contactNumber: Yup.string(),
  });

export const locationFormSchema = (t) =>
  Yup.object().shape({
    locationNameLocal: Yup.string().required(t('validation:location_name_required')),
    locationNameEnglish: Yup.string(),
    countryCode: countrySchema(t),
    locationType: Yup.string().required(t('validation:location_type_required')),
    locationCode: Yup.string(),
    postalCode: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    address1Local: Yup.string(),
    address2Local: Yup.string(),
    managerTelNumber: Yup.string(),
    weightUnit: weightUnitSchema(t),
    dimensionUnit: dimensionUnitSchema(t),
    isActive: Yup.boolean(),
  });

export const roleSchema = (t) => Yup.string().required(t('validation:role_required'));
export const countrySchema = (t) => Yup.string().required(t('validation:country_required'));
export const weightUnitSchema = (t) => Yup.string().required(t('validation:weight_unit_required'));
export const dimensionUnitSchema = (t) =>
  Yup.string().required(t('validation:dimension_unit_required'));
export const supplierSchema = (t) => Yup.string().required(t('validation:supplier_required'));

export const locationSchema = (t) => Yup.string().required(t('validation:location_required'));

export const purchaseOrderNumberSchema = (t) => Yup.string();
export const transferNumberSchema = (t) => Yup.string();

export const productsSchema = (t) =>
  Yup.array()
    .of(
      Yup.object().shape({
        sku: Yup.string().required(t('validation:sku_required')),
        productQuantity: Yup.number()
          .min(1, t('validation:product_quantity'))
          .typeError(t('validation:number')),
        supplierSku: Yup.string(),
      }),
    )
    .test({
      message: t('validation:products_required'),
      test: (value) => value.length > 0,
    });

export const itemsSchema = (t) =>
  Yup.array()
    .of(
      Yup.object().shape({
        sku: Yup.string().required('SKU is required'),
        unitPrice: Yup.number()
          .required('Unit price is required')
          .typeError(t('validation:number')),
        orderQuantity: Yup.number().min(1, t('validation:product_quantity')),
        shippingFee: Yup.number().nullable(true),
        tax: Yup.number().nullable(true),
        discountAmount: Yup.number().nullable(true),
      }),
    )
    .test({
      message: t('validation:products_required'),
      test: (value) => value.length > 0,
    });

export const variantsSchema = () =>
  Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      value: Yup.string(),
    }),
  );

export const bundleSchema = (t) =>
  Yup.array()
    .of(
      Yup.object().shape({
        sku: Yup.string().required(t('validation:bundle_sku_required')),
        quantity: Yup.number()
          .required(t('validation:bundle_sku_quantity_required'))
          .min(1, t('validation:product_quantity')),
      }),
    )
    .test({
      message: t('validation:products_required'),
      test: (value) => value.length > 0,
    });

export const bundleNotRequiredSchema = (t) =>
  Yup.array().of(
    Yup.object().shape({
      sku: Yup.string(),
      quantity: Yup.number().min(1, t('validation:product_quantity')),
    }),
  );

export const clientFormSchema = (t) =>
  Yup.object().shape({
    clientNameLocal: Yup.string().required(t('validation:channel_name_required')),
    clientNameEnglish: Yup.string(),
    clientType: Yup.string().required(t('validation:client_type_required')),
    userId: Yup.string().uuid(),
    countryCode: countrySchema(t),
    postalCode: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    address1Local: Yup.string(),
    address2Local: Yup.string(),
    representativeName: Yup.string(),
    businessRegistrationNumber: Yup.string(),
    representativeTelNumber: Yup.string(),
    representativeEmail: emailSchemaNotRequired(t),
    managerTelNumber: Yup.string(),
    managerEmail: emailSchemaNotRequired(t),
    description: Yup.string(),
  });

export const userIdSchema = (t) => Yup.string().required(t('validation:user_id_required'));

export const customerSchema = (t) => Yup.string().required(t('validation:customer_required'));

export const rackFormSchema = (t) =>
  Yup.object().shape({
    locationId: locationSchema(t),
    rackNumber: Yup.string().required(t('validation:rack_number_required')),
    rackType: Yup.string().required(t('validation:rack_type')),
    temperatureType: Yup.string().required(t('validation:temp_type')),
    rackSlotXPosition: Yup.number()
      .min(1, t('validation:quantity_min'))
      .max(200, t('validation:quantity_max')),
    rackSlotYPosition: Yup.number()
      .min(1, t('validation:quantity_min'))
      .max(200, t('validation:quantity_max')),
    isShipmentMatchAble: Yup.boolean().required(),
  });

export const toteFormSchema = (t) =>
  Yup.object().shape({
    locationId: locationSchema(t),
    toteNumber: Yup.string().required(t('validation:tote_number_required')),
    toteType: Yup.string().required(t('validation:tote_type_required')),
    description: Yup.string(),
    quantity: Yup.number()
      .required(t('validation:quantity_required'))
      .min(1, t('validation:quantity_min'))
      .max(200, t('validation:quantity_max')),
  });

export const mappingCodeSchema = (t) =>
  Yup.object().shape({
    mappingCode: Yup.string(),
  });

export const currencyCodeSchema = (t) =>
  Yup.string().required(t('validation:currency_code_required'));

export const packingMaterialFormSchema = (t) =>
  Yup.object().shape({
    locationId: locationSchema(t),
    packingMaterialNameLocal: Yup.string().required(t('validation:material_name_required')),
    packingMaterialType: Yup.string().required(t('validation:material_type_required')),
    weightUnit: weightUnitSchema(t),
    weight: Yup.number().min(0, t('validation:non_negative')),
    dimensionUnit: dimensionUnitSchema(t),
    length: Yup.number().min(0, t('validation:non_negative')),
    width: Yup.number().min(0, t('validation:non_negative')),
    height: Yup.number().min(0, t('validation:non_negative')),
    currencyCode: Yup.string(),
    purchaseCost: Yup.number().min(0, t('validation:non_negative')),
    packingMaterialQuantity: Yup.number().min(0, t('validation:non_negative')),
    packageId: Yup.number(),
    barcode: Yup.string(),
  });

export const shippingAddressSchema = (t) =>
  Yup.object()
    .shape({
      clearanceNumber: Yup.string(),
      firstNameLocal: Yup.string().required(t('validation:first_name_local_required')),
      lastNameLocal: Yup.string(),
      companyNameLocal: Yup.string(),
      countryCode: Yup.string().required(t('validation:country_code_required')),
      postalCode: Yup.string()
        .required(t('validation:postal_code_required'))
        .min(1, t('validation:postal_code_min_length')),
      city: Yup.string(),
      address1Local: Yup.string().required(t('validation:address1_local_required')),
      address2Local: Yup.string(),
      mobileNumber: Yup.string(),
      email: emailSchema(t),
    })
    .test({
      message: t('validation:shipping_address_required'),
      test: (value) => value.firstNameLocal !== '',
    });

export const billingAddressSchema = (t) =>
  Yup.object().shape({
    firstNameLocal: Yup.string().required(t('validation:first_name_local_required')),
    lastNameLocal: Yup.string(),
    companyNameLocal: Yup.string(),
    countryCode: Yup.string().required(t('validation:country_code_required')),
    postalCode: Yup.string()
      .required(t('validation:postal_code_required'))
      .min(1, t('validation:postal_code_min_length')),
    city: Yup.string(),
    address1Local: Yup.string().required(t('validation:address1_local_required')),
    address2Local: Yup.string(),
    mobileNumber: Yup.string(),
    email: emailSchema(t),
  });

export const billingAddressSchemaNotRequired = (t) =>
  Yup.object().shape({
    firstNameLocal: Yup.string(),
    lastNameLocal: Yup.string(),
    companyNameLocal: Yup.string(),
    countryCode: Yup.string(),
    postalCode: Yup.string(),
    city: Yup.string(),
    address1Local: Yup.string(),
    address2Local: Yup.string(),
    mobileNumber: Yup.string(),
    email: emailSchemaNotRequired(t),
  });

export const containersSchema = (t) =>
  Yup.array()
    .of(
      Yup.object().shape({
        height: Yup.number().required(t('common:required')).min(1, t('validation:non_negative')),
        length: Yup.number().required(t('common:required')).min(1, t('validation:non_negative')),
        width: Yup.number().required(t('common:required')).min(1, t('validation:non_negative')),
        courierId: Yup.number().required(t('validation:courier_is_required')),
      }),
    )
    .test({
      message: t('validation:container_required'),
      test: (value) => value.length > 0,
    });

export const nullableInteger = (t) =>
  Yup.number()
    .nullable()
    .transform((value, originalValue) => {
      if (typeof originalValue === 'string' && originalValue.trim() === '') {
        return null;
      }
      return value;
    })
    .min(0, t('validation:non_negative'));

export const machineEndpointsSchema = (t) =>
  Yup.array().of(
    Yup.object().shape({
      sequence: Yup.number(),
      endpoint: Yup.string(),
    }),
  );

export const apiItemsSchema = (t) =>
  Yup.array().of(
    Yup.object().shape({
      key: Yup.string(),
      value: Yup.string(),
    }),
  );

export const transferItemsSchema = (t) =>
  Yup.array()
    .of(
      Yup.object().shape({
        sku: Yup.string().required('SKU is required'),
        skuCountryCode: Yup.string().required('SKU country code is required'),
        transferQuantity: Yup.number().min(1, t('validation:transfer_quantity')),
        lotNumber: Yup.string().nullable(true),
        expirationDt: Yup.string().nullable(true),
        rackNumber: Yup.string().nullable(true),
        rackSlotNumber: Yup.string().nullable(true),
      }),
    )
    .test({
      message: t('validation:products_required'),
      test: (value) => value.length > 0,
    });
