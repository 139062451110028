import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import {
  ClipboardChecklistIcon,
  ClipboardCheckIcon,
  PackageIcon,
  DeliveryIcon,
  AlertTriangleIcon,
} from '@shopify/polaris-icons';
import { useGetUsersWithParams } from 'src/api/user_api';
import { chooseLan } from 'src/utils/language_code';
import { SingleSelectAutocomplete } from 'src/components/table/filter/autocomplete';
import { TextFieldFilter } from 'src/components/table/filter/text_field';
import { PrintIcon } from '@shopify/polaris-icons';
import BulkButton from 'src/components/bulk_button';

export const OUTSTATUS = {
  PICKING: 'Picking',
  PACKING: 'Packing',
  SHIPPING: 'Shipping',
  SHIPPED: 'Shipped',
  NOTREADYTOSHIP: 'NotReadyToShip',
  ALL: 'All',
};

export const getBadge = (status, children) => {
  if (status === OUTSTATUS.PICKING)
    return (
      <Badge
        tone={'attention'}
        icon={ClipboardChecklistIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.PACKING)
    return (
      <Badge
        tone="warning"
        icon={PackageIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.SHIPPING)
    return (
      <Badge
        tone="info"
        icon={ClipboardCheckIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.SHIPPED)
    return (
      <Badge
        tone="success"
        icon={DeliveryIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.NOTREADYTOSHIP)
    return (
      <Badge
        tone="critical"
        icon={AlertTriangleIcon}
      >
        {children}
      </Badge>
    );
  if (status === 'All') return <Badge tone="neutral"> {children} </Badge>;
};

export const tabs = (count) => {
  return [
    {
      label: i18n.t('common:tab.all'),
      value: '',
      onClick: () => {},
      badge: getBadge(OUTSTATUS.ALL, count.allCount),
      dataCount: count.allCount,
    },
    {
      label: i18n.t('common:tab.picking'),
      value: OUTSTATUS.PICKING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.PICKING, count.pickingCount),
      dataCount: count.pickingCount,
    },
    {
      label: i18n.t('common:tab.packing'),
      value: OUTSTATUS.PACKING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.PACKING, count.packingCount),
      dataCount: count.packingCount,
    },
    {
      label: i18n.t('common:tab.shipping'),
      value: OUTSTATUS.SHIPPING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.SHIPPING, count.shippingCount),
      dataCount: count.shippingCount,
    },
    {
      label: i18n.t('common:tab.shipped'),
      value: OUTSTATUS.SHIPPED,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.SHIPPED, count.shippedCount),
      dataCount: count.shippedCount,
    },
  ];
};

export const bulkAction = (data, selectedIds, handleSelectionChange) => [
  {
    content: (
      <BulkButton
        content={i18n.t('common:tracking_label')}
        icon={PrintIcon}
      />
    ),
    onAction: () => console.log('Todo: implement printing tracking labels'),
  },
];

export const headers = () => {
  return [
    { title: i18n.t('common:field.order') },
    { title: i18n.t('common:field.customer') },
    { title: i18n.t('common:field.tote') },
    { title: i18n.t('common:field.products') },
    { title: i18n.t('common:field.ready_to_ship_date') },
    { title: i18n.t('common:field.courier') },
    { title: i18n.t('common:field.tracking_number') },
    { title: i18n.t('common:field.status') },
    { title: i18n.t('common:field.shipped_date') },
  ];
};

export const initialFilters = {
  userId: { label: '', value: '' },
  tote: { label: '', value: '' },
};

export const filters = (state, setState) => [
  {
    key: 'tote',
    label: i18n.t('common:field.tote_number'),
    filter: (
      <TextFieldFilter
        label={i18n.t('common:field.tote_number')}
        value={state.tote.value}
        onEnter={(value) => {
          setState('tote', value);
        }}
      />
    ),
    shortcut: true,
    pinned: true,
  },
  {
    key: 'userId',
    label: i18n.t('common:field.customer'),
    filter: (
      <SingleSelectAutocomplete
        label={i18n.t('common:field.customer')}
        useOptions={useGetUsersWithParams}
        params={{
          pageSize: 5,
          pageNumber: 1,
          roleType: 'customer',
        }}
        searchParamName={'userName'}
        toOption={(d) => ({
          label: chooseLan(d.userFirstNameLocal, d.userFirstNameEnglish),
          value: d.userId,
        })}
        value={state.userId.value}
        onSelect={(value) => setState('userId', value)}
      />
    ),
    shortcut: true,
    pinned: true,
  },
];

export function disambiguateLabel(key, value) {
  switch (key) {
    case 'userId':
      return i18n.t('common:label.customer', { customer: value });
    case 'tote':
      return i18n.t('common:label.tote', { tote: value });
    default:
      return value;
  }
}
