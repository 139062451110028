import { useIndexResourceState } from '@shopify/polaris';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useDebounce } from './use_debounce';
import { useBoolean } from './use_boolean';
import { isInteger, isEmpty, isObject } from 'src/utils/type_check';
import PropTypes from 'prop-types';

export default function useTable(props) {
  const isRefetch = useBoolean(false);
  const useSortOptions = props.useSortOptions;
  const searching = props.searching;
  const title = props.title;
  const tabItems = props.tabItems || [];
  const searchQueryIcon = props.searchQueryIcon;
  const emptyStateMarkup = props.emptyStateMarkup;
  const searchOnEnter = props.searchOnEnter ?? false;
  const tableScroll = props.tableScroll ?? false;
  const showTabOnEmpty = props.showTabOnEmpty ?? true;
  // data need id column: this is used for selection
  const data = props.data;
  const headers = props.headers;
  const [isLoadedOnce, setIsLoadedOnce] = useState(false);

  useEffect(() => {
    if (!isEmpty(data)) {
      setIsLoadedOnce(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange: selectionChange,
  } = useIndexResourceState(props.data, {
    resourceIDResolver: props.resourceIdResolver ?? ((d) => d.id),
    resourceFilter: props.resourceFilter ?? undefined,
  });

  const [page, setPage] = useState(props?.defaultCurrentPage || 1);

  const [orderBy, setOrderBy] = useState(props?.orderBy ?? '');

  const [sortBy, setSortBy] = useState(props?.sortBy ?? '');

  const [tabIndex, setTabIndex] = useState(
    isEmpty(props?.defaultTab)
      ? 0
      : Math.max(
          tabItems.findIndex((x) => x.value === props?.defaultTab),
          0,
        ),
  );

  const [searchQuery, setSearchQuery] = useState('');

  const debounceQuery = useDebounce(searchQuery, props.debounceDelay, 500);

  const [enterQuery, setEnterQuery] = useState('');
  const [enterClickCount, setEnterClickCount] = useState(0);
  const debounceSearchQuery = props.searchOnEnter ? enterQuery : debounceQuery;

  const handleSelectionChange = useCallback(
    (selectionType, isSelecting, selection, position) => {
      if (selectionType === 'page') setEnterQuery('');
      if (!isSelecting) {
        props.onUnselect && props.onUnselect(selection || selectedResources);
        props.onReset && props.onReset(props.data, selection || selectedResources);
        setEnterQuery('');
      }
      selectionChange(selectionType, isSelecting, selection, position);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedResources, selectionChange],
  );

  useEffect(() => {
    if (selectedResources.length > 0) {
      handleSelectionChange('page', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const rowMarkup = useMemo(
    () =>
      props.rowMarkup({
        data: props.data,
        selectedItems: selectedResources,
        handleSelectionChange,
        searchQuery: debounceSearchQuery,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedResources, props.data, debounceSearchQuery, enterClickCount],
  );

  useEffect(() => {
    isRefetch.onTrue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceSearchQuery]);

  const [filterState, setFilterState] = useState(props.initialFilters || {});

  const filterValues = useMemo(() => {
    var ret = {};
    Object.keys(filterState).forEach((key) => {
      var val = filterState[key];
      if (key.includes('date')) ret[key] = val;
      else if (key.includes('date2')) ret[key] = val;
      else ret[key] = isObject(val) ? val.value : val;
    });
    return ret;
  }, [filterState]);

  const bulkAction = props.bulkAction
    ? props.bulkAction(props.data, selectedResources, handleSelectionChange)
    : [];

  const onChangeFilter = useCallback(
    (key, value) => {
      setFilterState((state) => ({ ...state, [key]: value }));
      isRefetch.onTrue();
    },
    [isRefetch],
  );

  const onClearFilter = useCallback(() => {
    setFilterState(props.initialFilters || {});
    isRefetch.onTrue();
  }, [props, isRefetch]);

  const filters = useMemo(() => {
    if (props.filters) {
      return props.filters(filterState, onChangeFilter);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, onChangeFilter, props.filters]);

  // text message for which filters are applied
  const appliedFilters = useMemo(() => {
    var temp = [];
    Object.keys(props.initialFilters || {}).forEach((key) => {
      const cur = filterState[key];
      var val = null;
      if (key.includes('date')) val = cur.fromDt;
      else if (key.includes('date2')) val = cur.fromDt;
      else val = isObject(cur) ? cur.value : cur;
      if (!isEmpty(val)) {
        temp.push({
          key,
          label: props.disambiguateLabel(key, cur?.label ?? cur),
          onRemove: () => onChangeFilter(key, props.initialFilters[key]),
        });
      }
    });
    return temp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState, onChangeFilter, props.initialFilters, props.disambiguateLabel]);

  const showSearchFilter = props.showSearchFilter ?? true;
  const selectable = props.selectable ?? true;
  const keepSelectedOnPagination = props.keepSelectedOnPagination ?? false;

  const rowsPerPageList = props.rowsPerPageList || [20, 40, 60];
  const [rowsPerPage, setRowsPerPage] = useState(props?.defaultRowsPerPage || rowsPerPageList[0]);

  const totalDataCount = props.totalDataCount
    ? props.totalDataCount
    : !isEmpty(tabItems)
      ? tabItems[tabIndex]?.dataCount ?? data.length
      : data.length;
  const showPagination = data.length > 0 && (props.showPagination ?? true);
  const showRowsPerPage = data.length > 0 && (props.showRowsPerPage ?? true);

  const pagination = useMemo(
    () => ({
      hasNext: page * rowsPerPage < totalDataCount,
      hasPrevious: page > 1,
      onNext: () => {
        setPage((p) => p + 1);
        isRefetch.onTrue();
        !props.keepSelectedOnPagination && handleSelectionChange('page', false);
      },
      onPrevious: () => {
        setPage((p) => p - 1);
        isRefetch.onTrue();
        !props.keepSelectedOnPagination && handleSelectionChange('page', false);
      },
      label: `${(page - 1) * rowsPerPage + 1}-${(page - 1) * rowsPerPage + data.length} of ${totalDataCount}`,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, rowsPerPage, data, totalDataCount, isRefetch],
  );

  const onChangeSort = useCallback(
    (value) => {
      if (value !== '') {
        setSortBy(value);
        isRefetch.onTrue();
      }
    },
    [isRefetch],
  );

  const onChangeOrder = useCallback(
    (value) => {
      if (value !== '') {
        setOrderBy(value);
        isRefetch.onTrue();
      }
    },
    [isRefetch],
  );

  const onChangeSearchQuery = useCallback(
    (value, isEnter = false) => {
      if (isEnter) {
        setSearchQuery('');
        setEnterQuery(value);
        setEnterClickCount((prev) => prev + 1);
      } else {
        setSearchQuery(value);
      }
      isRefetch.onTrue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRefetch],
  );

  const onChangeTab = useCallback(
    (e, value) => {
      if (isInteger(value)) {
        setTabIndex(value);
        isRefetch.onTrue();
      }
    },
    [isRefetch],
  );

  const onChangeRowsPerPage = useCallback(
    (value) => {
      setPage(1);
      setRowsPerPage(value);
      isRefetch.onTrue();
    },
    [isRefetch],
  );

  const onRefetched = useCallback(
    (deselectAll) => {
      if (isRefetch.value) {
        isRefetch.onFalse();
        if (deselectAll) {
          handleSelectionChange('page', false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRefetch],
  );

  const onResetPage = useCallback(() => {
    setPage(1);
  }, []);

  return {
    title,
    tabItems,
    useSortOptions,
    data,
    headers,
    rowMarkup,
    bulkAction,
    //
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    //
    page,
    pagination,
    orderBy,
    sortBy,
    rowsPerPage,
    rowsPerPageList,
    tabIndex,
    filters,
    appliedFilters,
    searchQuery,
    debounceSearchQuery,
    searching,
    searchQueryIcon,
    //
    onChangeTab,
    onChangeSort,
    onChangeOrder,
    onResetPage,
    onChangeRowsPerPage,
    onClearFilter,
    onChangeSearchQuery,
    searchOnEnter,
    //
    setRowsPerPage,
    showSearchFilter,
    showRowsPerPage,
    showPagination,
    selectable,
    keepSelectedOnPagination,
    showTabOnEmpty,
    //
    isRefetch,
    onRefetched,
    isLoadedOnce,
    //
    filterState,
    filterValues,
    emptyStateMarkup,
    tableScroll,
  };
}

useTable.propTypes = {
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  rowMarkup: PropTypes.func.isRequired,

  title: PropTypes.string,
  totalDataCount: PropTypes.number,

  tabItems: PropTypes.array,
  defaultTab: PropTypes.string,
  useSortOptions: PropTypes.func,
  resourceIdResolver: PropTypes.func,
  resourceFilter: PropTypes.func,
  defaultCurrentPage: PropTypes.number,
  bulkAction: PropTypes.array,

  searching: PropTypes.string,
  searchQueryIcon: PropTypes.element,
  debounceDelay: PropTypes.number,
  searchOnEnter: PropTypes.bool,
  onUnselect: PropTypes.func,
  onReset: PropTypes.func,

  emptyStateMarkup: PropTypes.func,

  initialFilters: PropTypes.object,
  filters: PropTypes.func,
  disambiguateLabel: PropTypes.func,

  showPagination: PropTypes.bool,
  showRowsPerPage: PropTypes.bool,
  showSearchFilter: PropTypes.bool,
  showTabOnEmpty: PropTypes.bool,
  selectable: PropTypes.bool,
  keepSelectedOnPagination: PropTypes.bool,
  rowsPerPageList: PropTypes.array,
  defaultRowsPerPage: PropTypes.number,

  tableScroll: PropTypes.bool,

  orderBy: PropTypes.string,
  sortBy: PropTypes.string,
};
