import { Box } from '@mui/material';
import { Navigation } from '@shopify/polaris';
import {
  ContractFilledIcon,
  ContractIcon,
  DeliveryFilledIcon,
  DeliveryIcon,
  HomeFilledIcon,
  HomeIcon,
  InventoryFilledIcon,
  InventoryIcon,
  OrderFilledIcon,
  OrderIcon,
  PackageFilledIcon,
  PackageIcon,
  SettingsFilledIcon,
  SettingsIcon,
} from '@shopify/polaris-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePathname } from 'src/hooks/router/use_pathname';
import { useHover } from 'src/hooks/use_hover';
import { PATHS } from 'src/routes/paths';
import { chooseLan } from 'src/utils/language_code';
import NavSectionVertical from 'src/components/navigation/vertical/nav_vertical';
import NavSectionMini from 'src/components/navigation/mini/nav_mini';
import { useGetRoleMenu } from 'src/api/menu_api';
import Loading from 'src/components/loading';

const menuNameEnglishForSettings = 'Setting';

const Navbar = ({ toggleSettingsModal, hasSettings }) => {
  const { i18n } = useTranslation();
  const pathName = usePathname();
  const [ref, isHover] = useHover();

  const [hovering, setHovering] = useState(isHover);
  const { searchResults, searchLoading } = useGetRoleMenu();

  const menuHelper = (menuName, childrenCount) => {
    menuName = menuName.toLowerCase();
    if (menuName === 'orders')
      return {
        path: PATHS.dashboard.order.order.root,
        icon: OrderFilledIcon,
        selectedIcon: OrderIcon,
      };
    if (menuName === 'merge orders') return { path: PATHS.dashboard.order.merge };
    if (menuName === 'inbound' && childrenCount > 0)
      return {
        path: PATHS.dashboard.inbound.root,
        icon: PackageFilledIcon,
        selectedIcon: PackageIcon,
      };
    if (menuName === 'inbound' && childrenCount === 0)
      return {
        path: PATHS.dashboard.inbound.inbound.root,
      };
    if (menuName === 'put away')
      return {
        path: PATHS.dashboard.inbound.putaway.root,
      };
    if (menuName === 'outbound' && childrenCount > 0)
      return {
        path: PATHS.dashboard.outbound.root,
        icon: DeliveryFilledIcon,
        selectedIcon: DeliveryIcon,
      };
    if (menuName === 'outbound' && childrenCount === 0)
      return {
        path: PATHS.dashboard.outbound.outbound.root,
        icon: DeliveryFilledIcon,
        selectedIcon: DeliveryIcon,
      };
    if (menuName === 'picking')
      return {
        path: PATHS.dashboard.outbound.picking.root,
      };
    if (menuName === 'packing')
      return {
        path: PATHS.dashboard.outbound.packing.root,
      };
    if (menuName === 'shipping scan')
      return {
        path: PATHS.dashboard.outbound.shipping.scan.root,
      };
    if (menuName === 'shipping container')
      return {
        path: PATHS.dashboard.outbound.shipping.containers.root,
      };
    if (menuName === 'inventory')
      return {
        path: PATHS.dashboard.inventory.root,
        icon: InventoryFilledIcon,
        selectedIcon: InventoryIcon,
      };
    if (menuName === 'products')
      return {
        path: PATHS.dashboard.inventory.products.root,
      };
    if (menuName === 'stock management')
      return {
        path: PATHS.dashboard.inventory.stock.root,
      };
    if (menuName === 'transfer')
      return {
        path: PATHS.dashboard.inventory.transfer.root,
      };
    if (menuName === 'purchasing')
      return {
        path: PATHS.dashboard.purchasing.root,
        icon: ContractFilledIcon,
        selectedIcon: ContractIcon,
      };
    if (menuName === 'purchase orders')
      return { path: PATHS.dashboard.purchasing.purchase_order.root };
    if (menuName === 'suppliers') return { path: PATHS.dashboard.purchasing.supplier.root };

    if (menuName === 'dashboard')
      return { path: PATHS.dashboard.root, icon: HomeFilledIcon, selectedIcon: HomeIcon };

    return { path: null, icon: null, selectedIcon: null };
  };

  const menuToNav = (m) => {
    if (m.menuNameEnglish === menuNameEnglishForSettings) {
      hasSettings.current = true;
      return {
        label: chooseLan(m.menuNameLocal, m.menuNameEnglish),
        icon: SettingsFilledIcon,
        selectedIcon: SettingsIcon,
        onClick: toggleSettingsModal,
      };
    } else {
      var { path, icon, selectedIcon } = menuHelper(m.menuNameEnglish, m.menuChildren.length);
      return {
        url: path,
        label: chooseLan(m.menuNameLocal, m.menuNameEnglish),
        icon: icon,
        selectedIcon: selectedIcon,
      };
    }
  };

  const navItems = useMemo(() => {
    return searchResults.map((m, index) => {
      var nav = menuToNav(m);
      if (m.menuChildren.length > 0) {
        nav.children = m.menuChildren.map((c) => {
          return menuToNav(c);
        });
      }
      return nav;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName, i18n, searchResults]);

  useEffect(() => {
    if (hovering && !isHover) {
      setHovering(isHover);
    }
  }, [isHover, setHovering, hovering]);

  return (
    <Box
      sx={{
        width: hovering ? '15rem' : '60px',
      }}
      className="dashboard_layout"
    >
      <Navigation location="/">
        <div ref={ref}>
          {searchLoading ? (
            <Loading />
          ) : (
            <>
              <div
                style={{
                  display: hovering ? 'block' : 'none',
                  minWidth: '15rem',
                  maxWidth: '15rem',
                }}
              >
                <NavSectionVertical data={navItems} />
              </div>
              <div
                style={{ display: hovering ? 'none' : 'block', minWidth: '60px', maxWidth: '60px' }}
              >
                <NavSectionMini
                  data={navItems}
                  setHovering={setHovering}
                  hovering={hovering}
                />
              </div>
            </>
          )}
        </div>
      </Navigation>
    </Box>
  );
};

export default Navbar;
