import { useFormContext, Controller } from 'react-hook-form';
import {
  Box,
  Chip,
  MenuItem,
  Checkbox,
  InputLabel,
  FormControl,
  OutlinedInput,
  FormHelperText,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../loading';
import { CheckIcon } from '@shopify/polaris-icons';

// ----------------------------------------------------------------------

export function RHFAPISelect({ name, displayName, useOptions, params, toOption, ...other }) {
  const { searchResults, searchLoading } = useOptions(params ?? {});
  const options = useMemo(() => {
    return searchResults.map((r) => toOption(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  if (searchLoading) {
    return <Loading />;
  }

  return (
    <RHFSelect
      name={name}
      native
      displayName={displayName}
      {...other}
    >
      {options.map((c) => (
        <option
          key={c.key}
          value={c.key}
        >
          {c.value}
        </option>
      ))}
    </RHFSelect>
  );
}

export function RHFSelect({
  name,
  displayName,
  native,
  maxHeight = 220,
  helperText,
  children,
  PaperPropsSx,
  styles,
  disabled = false,
  ...other
}) {
  const { control } = useFormContext();
  const selectStyles = {
    root: {
      '& .MuiInputBase-root': {
        borderRadius: 'var(--p-space-200)',
        backgroundColor: disabled ? 'var(--p-color-bg-surface-disabled)' : 'white',
        marginTop: 'var(--p-space-100)',
      },
      '& .MuiNativeSelect-select': {
        padding: 'var(--p-space-150) var(--p-space-300)',
      },
      '& fieldset': {
        borderColor: disabled
          ? 'var(--p-color-bg-surface-disabled) !important'
          : 'var(--p-color-border-tertiary)',
      },
    },
  };

  const mergedStyles = {
    ...selectStyles,
    ...styles,
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Box sx={{ width: '100%' }}>
          <InputLabel>
            <Typography variant="body_md">{displayName}</Typography>
          </InputLabel>
          <TextField
            {...field}
            select
            fullWidth
            SelectProps={{
              native,
              MenuProps: {
                PaperProps: {
                  sx: {
                    ...(!native && {
                      maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                    }),
                    ...PaperPropsSx,
                  },
                },
              },
              sx: { textTransform: 'capitalize' },
            }}
            variant={disabled ? 'filled' : 'outlined'}
            error={!!error}
            helperText={error ? error?.message : helperText}
            sx={mergedStyles.root}
            disabled={disabled}
            {...other}
          >
            <option
              key={'blank_option'}
              value={''}
              style={{ display: 'none' }}
            />
            {children}
          </TextField>
        </Box>
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFAPIBorderlessSelect({
  name,
  displayName,
  native,
  maxHeight = 220,
  helperText,
  displayText,
  useOptions,
  params,
  toOption,
  sx,
  transferOption = false,
  ...other
}) {
  const { control } = useFormContext();

  const { searchResults, searchLoading } = useOptions(params);
  const [value, setValue] = useState('');
  const options = useMemo(() => {
    return searchResults.map((r) => toOption(r));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults]);

  const fieldRef = useRef(null);

  useEffect(() => {
    if (!searchLoading && options.length === 1) {
      const selectedValue = options[0].value;
      setValue(selectedValue);
      if (fieldRef.current) {
        fieldRef.current.onChange(selectedValue);
      }
    }
  }, [searchResults, searchLoading, options]);

  useEffect(() => {
    if (fieldRef?.current?.value) {
      setValue(fieldRef?.current?.value);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRef?.current]);

  const handleValueChange = (e, fieldOnChange) => {
    const newValue = e.target.value;
    setValue(newValue);
    fieldOnChange(newValue);
  };

  if (searchLoading) {
    return <Loading />;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        fieldRef.current = field;
        return (
          <FormControl sx={{ ...sx }}>
            <Typography variant={'heading_sm'}>{displayName}</Typography>
            <Box height={'0.5rem'} />
            <Select
              {...field}
              variant="standard"
              disableUnderline
              displayEmpty
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  fontSize="small"
                  sx={{ position: 'absolute', right: 0, pointerEvents: 'none', color: 'icon' }}
                />
              )}
              value={value}
              onChange={(e) => handleValueChange(e, field.onChange)}
              renderValue={(selected) => (
                <Typography
                  variant="heading_lg"
                  color={'text.primary'}
                  sx={{ paddingLeft: '4px' }}
                >
                  {!selected
                    ? displayText
                    : options?.find((x) => x.value === selected)?.label ?? ''}
                </Typography>
              )}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                MenuListProps: {
                  disablePadding: true,
                },
                PaperProps: {
                  sx: {
                    marginTop: 'var(--p-space-150)',
                    borderRadius: '12px',
                    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                  },
                },
              }}
              sx={{
                '& .MuiSelect-select': {
                  '&:focus': {
                    borderRadius: '10px',
                    backgroundColor: 'rgba(128, 128, 128, 0.2)',
                  },
                  '&:hover': {
                    borderRadius: '10px',
                    backgroundColor: 'rgba(128, 128, 128, 0.2)',
                  },
                },
              }}
              {...other}
            >
              {options.map((option) => {
                if (transferOption && transferOption === option.value) {
                  return null;
                } else {
                  return (
                    <MenuItem
                      sx={{
                        '&.Mui-selected': {
                          borderRadius: '10px',
                          backgroundColor: 'rgba(128, 128, 128, 0.2)',
                        },
                        '&:hover': {
                          borderRadius: '10px',
                          backgroundColor: 'rgba(128, 128, 128, 0.2)',
                        },
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        margin: '6px',
                        paddingX: '6px',
                      }}
                      key={option.value}
                      value={option.value}
                    >
                      <Typography sx={{ flexGrow: 1 }}>{option.label}</Typography>
                      {option.value === field.value && <CheckIcon height={'20px'} />}
                    </MenuItem>
                  );
                }
              })}
            </Select>

            {(!!error || helperText) && (
              <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}) {
  const { control } = useFormContext();

  const renderValues = (selectedIds) => {
    const selectedItems = options.filter((item) => selectedIds.includes(item.value));

    if (!selectedItems.length && placeholder) {
      return (
        <Box
          component="em"
          sx={{ color: 'text.disabled' }}
        >
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems.map((item) => (
            <Chip
              key={item.value}
              size="small"
              label={item.label}
            />
          ))}
        </Box>
      );
    }

    return selectedItems.map((item) => item.label).join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={sx}>
          {label && <InputLabel id={name}> {label} </InputLabel>}

          <Select
            {...field}
            multiple
            displayEmpty={!!placeholder}
            labelId={name}
            input={
              <OutlinedInput
                fullWidth
                label={label}
                error={!!error}
              />
            }
            renderValue={renderValues}
            {...other}
          >
            {placeholder && (
              <MenuItem
                disabled
                value=""
              >
                <em> {placeholder} </em>
              </MenuItem>
            )}

            {options.map((option) => {
              const selected = field.value.includes(option.value);

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                >
                  {checkbox && (
                    <Checkbox
                      size="small"
                      disableRipple
                      checked={selected}
                    />
                  )}

                  {option.label}
                </MenuItem>
              );
            })}
          </Select>

          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
